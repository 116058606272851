import { Button, CircularProgress, Tooltip } from '@material-ui/core';
import React, { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import styled from 'styled-components';
import { ProjectDetailsHeading } from './Project';
import moment from 'moment';
import {
  clearEventSelectionState,
  setHasClickedMultiLanguageEvent,
  setIsAllProjectsLoaded,
  setIsNumAttendeesDialogOpen,
  setSelectedEvent,
  fetchRegisterForEvent
} from '../../store/slices/projects';
import { Link } from 'react-router-dom';
import InputNumAttendeesDialog from './InputNumAttendeesDialog';
import { environment } from '../../environment';
import { EventSettingsENUM } from '../../models/enums/EventSettingsENUM';

const GreenButton = styled(Button)`
  min-width: 130px;
  max-width: 130px;
  font-size: 10px;
  padding: 4px;
  background-color: #21890e;
  color: white;
  &:hover {
    background-color: #21890e;
  }
`;

const GreyDisabledButton = styled(Button)`
  min-width: 130px;
  max-width: 130px;
  font-size: 10px;
  padding: 4px;
  color: black !important;
`;

const BlueButton = styled(Button)`
  min-width: 130px;
  max-width: 130px;
  font-size: 10px;
  padding: 4px;
  &:disabled {
    color: black;
  }
`;

const EvtFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

const MyUL = styled.ul`
  margin: 0px;
  padding: 0px;
  list-style: none;
`;

const isInPersonRegistrationDisabled = (eventDateStart: string): boolean => {
  const evtDate: number = new Date(eventDateStart).getTime();
  const now: number = new Date().getTime();
  const ONE_HOUR = 60 * 60 * 1000; /* ms */
  const difference = evtDate - now;
  return difference > ONE_HOUR ? true : false;
};

const isInPersonMeetingExpired = (eventDateEnd: string): boolean => {
  const evtDateEnd: number = moment(eventDateEnd)
    .utcOffset(eventDateEnd)
    .valueOf();
  const now: number = new Date().getTime();
  const isExpired = now <= evtDateEnd ? false : true;
  return isExpired;
};

const isOnlineMeetingExpired = (eventDateTime: string): boolean => {
  // expires after 2 weeks
  const evtDate: number = new Date(eventDateTime).getTime();
  const TWO_WEEKS = 1209600000; /* ms */
  const now: number = new Date().getTime();
  return evtDate + TWO_WEEKS < now;
};

interface Props {
  projectId: number;
}
export default function ProjectEvents({ projectId }: Props): ReactElement {
  const dispatch = useDispatch();
  const {
    selectedProjectEvents,
    isLoadingProjectEvents,
    isNumAttendeesDialogOpen,
    selectedEventDetails
  } = useSelector((state: RootState) => state).projectsSlice;
  const { stakeholderInfo } = useSelector(
    (state: RootState) => state
  ).myProfileSlice;

  const joinOnlineMeeting = (evt) => {
    if (stakeholderInfo) {
      if (evt.OnlineMeetings.length) {
        // is Multi-Language event
        dispatch(setHasClickedMultiLanguageEvent(true));
      } else {
        const { PeId: eventId, PeGuid, EventSettings, OnlineResource } = evt;
        /* EventSettings is a bitflag accounting for isVPH, isPodium, and isAnon
          public enum EventSettings : int
          {
          IS_EMBEDDED = 1 << 0,
          IS_PODIUM = 1 << 1,
          IS_ANON = 1 << 2,
          }
        */
        // TODO: confirm what EventSettings 8, 9, 10 and 11 mean for Iowa - this may not be the appropriate way to handle
        if (
          !(EventSettings & EventSettingsENUM.IS_PODIUM) &&
          (EventSettings & EventSettingsENUM.IS_EMBEDDED ||
            EventSettings & EventSettingsENUM.IS_ANON)
        ) {
          // is embedded but not podium (do not include "stakeholder" in VPH link)
          window.open(
            `${environment.onlineMeetingsUrl}/?peGuid=${PeGuid}&stakeGuid=${stakeholderInfo.STAKE_GUID}`
          );
        } else if (
          EventSettings & EventSettingsENUM.IS_PODIUM &&
          (EventSettings & EventSettingsENUM.IS_EMBEDDED ||
            EventSettings & EventSettingsENUM.IS_ANON)
        ) {
          // IF 2: is embedded podium (include "stakeholder" in VPH link)
          // IF 7: all 3 bools are true including isAnon - handle the same as EventSettings = 2 because isAnon applies to the event registration form. We already know the user in REACH so they can't be anon.
          window.open(
            `${environment.onlineMeetingsUrl}/stakeholder?peGuid=${PeGuid}&stakeGuid=${stakeholderInfo.STAKE_GUID}`
          );
        }
      }
    }
  };

  const switchEnvironmentTimeZone = (entityName: string) => {
    switch (entityName) {
      case 'Iowa DOT':
        return 'CT';
      case 'Mass DOT':
        return 'ET';
      case 'Maine DOT':
        return 'ET';
      case 'Connecticut DOT':
        return 'ET';
      default:
        throw new Error('unhandled case in switchEnvironmentTimeZone');
    }
  };
  return (
    <div>
      {isLoadingProjectEvents && (
        <>
          <span
            role="alert"
            style={{ position: 'absolute', marginLeft: -9999 }}
          >
            Loading project events
          </span>
          <CircularProgress size={20} />
        </>
      )}
      {selectedProjectEvents && (
        <div style={{ margin: '10px 0px' }}>
          <ProjectDetailsHeading>
            Events:
            {!selectedProjectEvents.length && (
              <span style={{ fontWeight: 'normal' }}>
                {' None posted at this time.'}
              </span>
            )}
          </ProjectDetailsHeading>
          {!!selectedProjectEvents.length &&
            selectedProjectEvents.map((evt, idx) => (
              <MyUL key={idx}>
                <EvtFlexContainer>
                  <div>
                    <div>{`${evt.PeName}`}</div>
                    <div>
                      {`${moment(evt.EventDate).format(
                        'MMMM Do YYYY'
                      )} - ${moment(evt.EventDate)
                        .add(14, 'days')
                        .format('MMMM Do YYYY')}`}
                    </div>
                  </div>
                  {/* v1 works */}
                  {/* <div>
                    <div>
                      {`${moment(evt.EventDate).format('MMMM Do YYYY')}: ${
                        evt.PeName
                      }`}
                    </div>
                    <div>{`${moment(evt.EventStartDateTime)
                      .utcOffset(evt.EventStartDateTime)
                      .format('h:mmA')} - ${moment(evt.EventEndDateTime)
                      .utcOffset(evt.EventStartDateTime)
                      .format('h:mmA')} ${switchEnvironmentTimeZone(
                      environment.entityName
                    )}`}</div>
                  </div> */}
                  <>
                    {evt.OnlineResourceGuid && evt.OnlineResourceGuid.length ? (
                      // is an online meeting
                      <>
                        {isOnlineMeetingExpired(evt.EventEndDateTime) ? (
                          <GreyDisabledButton
                            variant="contained"
                            disabled={true}
                          >
                            Comment Period Expired
                          </GreyDisabledButton>
                        ) : !evt.IsRegistered ? (
                          <BlueButton
                            onClick={() => {
                              dispatch(dispatch(setSelectedEvent(evt)));
                              // Disabled MAIN-315
                              // dispatch(setIsNumAttendeesDialogOpen(true));
                              dispatch(
                                fetchRegisterForEvent({
                                  eventId: evt.PeId,
                                  attendanceCount: 1
                                })
                              );
                              if (
                                evt.EventSettings === 8 ||
                                (evt.EventSettings === 0 && evt.OnlineResource)
                              ) {
                                window.open(evt.OnlineResource, '_blank');
                              } else {
                                joinOnlineMeeting(evt);
                              }
                            }}
                            aria-label="Attend Online"
                            color="primary"
                            variant="contained"
                          >
                            Attend Online
                          </BlueButton>
                        ) : (
                          <Tooltip title="You are already registered for this online meeting. Click to join it.">
                            <GreenButton
                              onClick={() => {
                                if (
                                  evt.EventSettings === 8 ||
                                  (evt.EventSettings === 0 &&
                                    evt.OnlineResource)
                                ) {
                                  window.open(evt.OnlineResource, '_blank');
                                } else {
                                  joinOnlineMeeting(evt);
                                }
                              }}
                              variant="contained"
                            >
                              REGISTERED ONLINE: Join Meeting
                            </GreenButton>
                          </Tooltip>
                        )}
                      </>
                    ) : (
                      // is an in-person meeting
                      <>
                        {isInPersonMeetingExpired(evt.EventEndDateTime) ? (
                          <GreyDisabledButton
                            variant="contained"
                            disabled={true}
                          >
                            Event Expired
                          </GreyDisabledButton>
                        ) : !evt.IsRegistered ? (
                          <Tooltip
                            title={
                              isInPersonRegistrationDisabled(
                                evt.EventStartDateTime
                              )
                                ? 'Registration will be enabled 1 hour prior to the meeting.'
                                : ''
                            }
                          >
                            <span>
                              <BlueButton
                                onClick={() => {
                                  dispatch(dispatch(setSelectedEvent(evt)));
                                  // Disabled MAIN-315
                                  // dispatch(setIsNumAttendeesDialogOpen(true));
                                  dispatch(
                                    fetchRegisterForEvent({
                                      eventId: evt.PeId,
                                      attendanceCount: 1
                                    })
                                  );
                                  if (
                                    evt.EventSettings === 8 ||
                                    (evt.EventSettings === 0 &&
                                      evt.OnlineResource)
                                  ) {
                                    window.open(evt.OnlineResource, '_blank');
                                  } else {
                                    joinOnlineMeeting(evt);
                                  }
                                }}
                                disabled={isInPersonRegistrationDisabled(
                                  evt.EventStartDateTime
                                )}
                                color="primary"
                                variant="contained"
                              >
                                Attend In-Person
                              </BlueButton>
                            </span>
                          </Tooltip>
                        ) : (
                          <Tooltip title="You are already registered for this in-person meeting.">
                            <span>
                              <GreyDisabledButton
                                variant="contained"
                                disabled={true}
                              >
                                REGISTERED IN-PERSON
                              </GreyDisabledButton>
                            </span>
                          </Tooltip>
                        )}
                      </>
                    )}
                  </>
                  {/* Disabled MAIN-315 */}
                  {isNumAttendeesDialogOpen && (
                    <InputNumAttendeesDialog projectId={projectId} />
                  )}
                </EvtFlexContainer>
              </MyUL>
            ))}
        </div>
      )}
    </div>
  );
}
